import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  performanceMonitor, catchJsError, behaviorMonitor, catchXhrError,
} from '@amzn/awslog-amonitor';

import App from './App';
import '@amzn/awsui-global-styles/polaris.css';
import './index.scss';
import { store } from './app/store';
import { REPORT_URL } from './api/constant';
import { CURRENT_CONTEXT, log } from './utils/monitor';

try {
  catchJsError(REPORT_URL, CURRENT_CONTEXT);
  behaviorMonitor(REPORT_URL, CURRENT_CONTEXT);
  performanceMonitor(REPORT_URL, CURRENT_CONTEXT);
  catchXhrError(REPORT_URL, CURRENT_CONTEXT);
} catch (e) {
  log('ERROR', `monitor error ${String(e)}`);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
