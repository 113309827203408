/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';
import {
  BreadcrumbGroup, Header, Button, Container, SpaceBetween, StatusIndicator, Link,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import {
  getChangeEmailUrl,
  getChangePasswordUrl,
  getChangePhoneUrl,
  getDeleteAccountUrl,
  getBindUserUrl,
  getUnbindUserUrl,
  getFaqUrl, getManageMfaUrl,
} from '../../api/constant';
import BackgroundImg from '../../../public/background.png';
import { metric } from '../../utils/monitor';
import { defaultAccountInfoState } from '../../constants/config';
import { isFeatureEnabled } from '../../utils/featureToggle';

import { UserType } from './constant';
import './index.scss';

const SecurityInfo = () => {
  const { accountInfo } = useAppSelector((state) => state?.info);
  const [userType, setUserType] = useState<UserType>();

  const [isClassicUser, setIsClassicUser] = useState<boolean>(true);
  const [isHaveWechatId, setIsHaveWechatId] = useState<boolean>(false);

  const navigate = useNavigate();

  const redirectUserTo1P = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (accountInfo === defaultAccountInfoState) return;
    const isWechatUser = accountInfo?.wechatId && accountInfo.wechatId !== '' && !accountInfo.mobilePhoneNumber;
    if (accountInfo?.mobilePhoneNumber) {
      setUserType(!accountInfo?.wechatId ? UserType.CLASSIC_NORMAL_USER : UserType.CLASSIC_BIND_WECHAT_USER);
    } else if (isWechatUser) {
      setUserType(!accountInfo?.existDuplicateUser ? UserType.WECHAT_USER
        : UserType.WECHAT_USER_EXIST_DUPLICATE_ACCOUNT_USER);
    } else {
      navigate('/Error/INVALID_USER_ACCOUNT');
    }
  }, [accountInfo]);

  useEffect(() => {
    setIsClassicUser(
      userType === UserType.CLASSIC_NORMAL_USER || userType === UserType.CLASSIC_BIND_WECHAT_USER ? true : false,
    );
    setIsHaveWechatId(!!accountInfo?.wechatId);
  }, [userType, accountInfo?.wechatId]);

  useEffect(() => {
    metric([], 'Security Page PV', 1, [], 'Count');
  }, []);

  return (
    <div className="security-info">
      <div>
        <div className="security-breadcrumb">
          <BreadcrumbGroup
            items={[
              { text: '用户中心', href: '/' },
              { text: '账户与安全', href: '/security-info' },
              {
                text: '账户信息',
                href: '/security-info/account',
              },
            ]}
            ariaLabel="Breadcrumbs"
          />
        </div>
        <div className="security-header">
          <Header
            variant="h1"
            actions={isClassicUser && (
              <Button
                iconAlign="right"
                iconName="external"
                onClick={() => {
                  metric([], 'Click Change Passport Button', 1, [], 'Count');
                  redirectUserTo1P(getChangePasswordUrl());
                }}
              >
                修改密码

              </Button>
            )}
          >
            账户信息
          </Header>
        </div>
        {(accountInfo && userType)
          ? (
            <div className="security-content-container">
              {userType && userType === UserType.WECHAT_USER_EXIST_DUPLICATE_ACCOUNT_USER
                && (
                  <p className="security-duplicate-reminder">
                    手机号码重复提示：检测到您使用的手机号码创建过另一个亚马逊云科技市场活动与社区账户，您可以在
                    <Link external href={getFaqUrl()}>常见问题</Link>
                    中查看更多操作说明。
                  </p>
                )}
              <SpaceBetween size="l">
                {
                  (userType === UserType.CLASSIC_BIND_WECHAT_USER || userType === UserType.CLASSIC_NORMAL_USER) ? (
                    <Container
                      header={(
                        <Header
                          variant="h2"
                          actions={
                            (!isHaveWechatId ? (
                              <Button
                                iconAlign="right"
                                iconName="external"
                                data-testid="bind-user-button"
                                onClick={() => {
                                  redirectUserTo1P(getBindUserUrl());
                                }}
                              >
                                立即绑定
                              </Button>
                            ) : (
                              <Button
                                iconAlign="right"
                                iconName="external"
                                data-testid="unbind-user-button"
                                onClick={() => {
                                  redirectUserTo1P(getUnbindUserUrl());
                                }}
                              >
                                解除绑定
                              </Button>
                            ))
                          }
                        >
                          绑定微信账户

                        </Header>
                      )}
                    >
                      {isHaveWechatId ? '已绑定' : '未绑定'}
                    </Container>
                  ) : (
                    <Container
                      header={(
                        <Header
                          variant="h2"
                        >
                          注册方式
                        </Header>
                      )}
                    >
                      绑定微信账户
                    </Container>
                  )
                }
                {isClassicUser
                  && (
                    <>
                      <Container
                        header={(
                          <Header
                            variant="h2"
                            actions={(
                              <Button
                                iconAlign="right"
                                iconName="external"
                                data-testid="change-email-button"
                                onClick={() => {
                                  metric([], 'Click Change Email Button', 1, [], 'Count');
                                  redirectUserTo1P(getChangeEmailUrl());
                                }}
                              >
                                修改

                              </Button>
                            )}
                          >
                            注册邮箱地址
                          </Header>
                        )}
                      >
                        {accountInfo?.email}
                      </Container>

                      <Container
                        header={(
                          <Header
                            variant="h2"
                            actions={(
                              <Button
                                iconAlign="right"
                                iconName="external"
                                data-testid="change-phone-button"
                                onClick={() => {
                                  metric([], 'Click Change Phone Number Button', 1, [], 'Count');
                                  redirectUserTo1P(getChangePhoneUrl());
                                }}
                              >
                                修改

                              </Button>
                            )}
                          >
                            注册手机号码
                          </Header>
                        )}
                      >
                        {accountInfo?.mobilePhoneNumber}
                      </Container>

                      {isFeatureEnabled('MANAGE_MFA_FEATURE_TOGGLE')
                          && (
                          <Container
                            header={(
                              <Header
                                variant="h2"
                                actions={(
                                  <Button
                                    iconAlign="right"
                                    iconName="external"
                                    data-testid="manage-mfa-button"
                                    onClick={() => {
                                      metric([], 'Click Manage MFA Button', 1, [], 'Count');
                                      redirectUserTo1P(getManageMfaUrl());
                                    }}
                                  >
                                    注册/管理设备

                                  </Button>
                                  )}
                              >
                                多重身份验证(MFA)设备
                              </Header>
                          )}
                          >
                            如果需要提高您账户的安全性，您可以注册MFA设备。
                          </Container>
                          )}
                    </>
                  )}
                <Container
                  header={(
                    <Header
                      variant="h2"
                      actions={(
                        <Button
                          iconAlign="right"
                          iconName="external"
                          data-testid="delete-account-button"
                          onClick={() => {
                            metric([], 'Click Delete Account Button', 1, [], 'Count');
                            redirectUserTo1P(getDeleteAccountUrl());
                          }}
                        >
                          注销账户

                        </Button>
                      )}
                    >
                      注销账户
                    </Header>
                  )}
                >
                  如果您选择注销您的账户，您将无法再登录亚马逊云科技市场活动与社区。我们将自动删除与您的此账户的关联数据，之后您将无法恢复这些数据。
                </Container>
              </SpaceBetween>
            </div>
          )
          : <StatusIndicator type="loading" />}
      </div>

      <div>
        <img src={BackgroundImg} alt="" className="background-img" />
      </div>
    </div>
  );
};

export default SecurityInfo;
