import React, { useEffect } from 'react';
import {
  Alert, Button,
} from '@amzn/awsui-components-react';
import { useNavigate, useParams } from 'react-router-dom';

import { setIsCheckingAuthStatus } from '../../app/infoReducer';
import { useAppDispatch } from '../../app/hooks';

const ErrorBanner = () => {
  const navigate = useNavigate();
  const { errorType } = useParams();
  const dispatch = useAppDispatch();

  let errorMessage = '';
  switch (errorType) {
    case 'BAD_REQUEST':
      errorMessage = '页面错误，请稍后重试。';
      break;
    case 'INTERNAL_SERVER_ERROR':
      errorMessage = '系统错误，请稍后重试。';
      break;
    case 'NO_PERMISSION':
      errorMessage = '请检查权限后重试。';
      break;
    case 'NOT_FOUND':
      errorMessage = '页面不存在。';
      break;
    case 'TOO_MANY_REQUESTS':
      errorMessage = '请求过于频繁，请稍后重试。';
      break;
    case 'INVALID_USER_ACCOUNT':
      errorMessage = '用户账号有误，请稍后重试。';
      break;
    default:
      errorMessage = '未知错误，请稍后重试。';
  }

  const onClickRetry = () => {
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    dispatch(setIsCheckingAuthStatus(false));
  }, []);

  return (
    <Alert
      data-testid="error-alert"
      statusIconAriaLabel="Error"
      type="error"
      action={<Button onClick={() => onClickRetry()}>点击重试</Button>}
    >
      {errorMessage}
    </Alert>
  );
};

export default ErrorBanner;
