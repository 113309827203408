import React, { useState, useEffect } from 'react';
import type { SideNavigationProps } from '@amzn/awsui-components-react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setIsConfirmationModalStatus, setOnNavigationHref } from '../../app/infoReducer';
import { mobileInnerWidth } from '../../constants/config';

const navigationItem: readonly SideNavigationProps.Item[] = [
  {
    type: 'link-group',
    text: '我的信息',
    href: '/my-info',
    items: [
      {
        type: 'link',
        text: '基本信息',
        href: '/my-info/basic',
      },
    ],
  },
  {
    type: 'link-group',
    text: '账户与安全',
    href: '/security-info',
    items: [
      {
        type: 'link',
        text: '账户信息',
        href: '/security-info/account',
      },
    ],
  },
];

interface NavigationProps {
  onNavigationToggle: (status: boolean) => void;
}

const Navigation = (props: NavigationProps) => {
  const { onNavigationToggle } = props;
  const {
    isEditingPersonStatus,
    isEditingCompanyStatus,
    isEditingUserAgreement,
  } = useAppSelector((state) => state?.info);
  const dispatch = useAppDispatch();
  const [activeHref, setActiveHref] = useState<string>(window.location.pathname);
  const navigate = useNavigate();

  const onFollow = (event: any) => {
    event.preventDefault();
    if (isEditingPersonStatus || isEditingCompanyStatus || isEditingUserAgreement) {
      dispatch(setOnNavigationHref(event.detail.href));
      dispatch(setIsConfirmationModalStatus(true));
    } else if (!event.detail.external) {
      setActiveHref(event.detail.href);
      navigate(event.detail.href);
      if (window.innerWidth <= mobileInnerWidth) {
        onNavigationToggle(false);
      }
    }
  };

  useEffect(() => {
    const urlSegment = window.location.pathname.split('/').slice(1, 3).join('/');
    setActiveHref(`/${urlSegment}`);
  }, [window.location.pathname]);

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '/', text: '用户中心' }}
      onFollow={(event) => onFollow(event)}
      items={navigationItem}
    />
  );
};

export default Navigation;
