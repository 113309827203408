export const idTitleMap = {
  companyName: '公司名称',
  jobRole: '工作职务',
  companySize: '公司规模',
  industry: '所属行业',
  province: '省份',
  city: '城市',
  address: '公司通信地址',
  postalCode: '邮政编码',
  website: '公司网站',
  // informationRelationship: '我填写的这些信息涉及我的',
};

export enum InfoDataField {
  COMPANY_NAME = 'companyName',
  JOB_ROLE = 'jobRole',
  COMPANY_SIZE = 'companySize',
  INDUSTRY = 'industry',
  PROVINCE = 'province',
  CITY = 'city',
  ADDRESS = 'address',
  POSTAL_CODE = 'postalCode',
  WEBSITE = 'website',
  INFORMATION_RELATIONSHIP = 'informationRelationship',
  FULL_NAME = 'fullName',
  NICK_NAME = 'nickName',
  SELF_INTRO = 'selfIntro',
  AVATAR_URL = 'avatarUrl',
  CONTACT_PHONE_NUMBER = 'contactPhoneNumber',
  OTP_CODE = 'otpCode',
  CONTACT_EMAIL = 'contactEmail',
}
