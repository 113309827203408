/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import './index.scss';
import { Icon, ButtonDropdown } from '@amzn/awsui-components-react';

import AWSHeader from '../../../public/header.png';
import { useAppSelector } from '../../app/hooks';
import { CREATE_ACCOUNT_ITEMS, NAVIGATION_ITEMS_MOBILE, defaultHeaderSectionConfig } from '../header/constant';

const MobileHeader = () => {
  const { userInfo } = useAppSelector((state) => state?.info);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isSectionOpen, setIsSectionOpen] = useState(defaultHeaderSectionConfig);

  const onClickDropDownItem = (id: string) => {
    const newIsSectionOpen = { ...isSectionOpen };
    Object.keys(newIsSectionOpen).forEach((key) => {
      if (key === id) {
        newIsSectionOpen[key] = !newIsSectionOpen[id];
      } else {
        newIsSectionOpen[key] = false;
      }
    });
    setIsSectionOpen(newIsSectionOpen);
  };

  useEffect(() => {
    const buttonDropDownContent = document.querySelectorAll('[class*=awsui_button-dropdown]');
    if (buttonDropDownContent.length) {
      const bdc = buttonDropDownContent[0] as HTMLElement;
      bdc.style.width = '100%';

      const buttonContent = buttonDropDownContent[0].querySelector('button') as HTMLElement;
      buttonContent.style.width = '100%';
      buttonContent.style.display = 'flex';
      buttonContent.style.justifyContent = 'center';
    }
  }, []);

  return (
    <div id="header-bar">
      <div className="mobile-header-wrapper">
        <div className="mobile-header-icon">
          <img src={AWSHeader} alt="" className="mobile-header-logo" />
          <div
            className={isDropDownOpen ? 'mobile-header-icon-dropdown-open' : ''}
            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
            onKeyDown={() => {}}
          >
            <Icon name="menu" />
          </div>
        </div>

        <div className="awsui-visual-refresh mobile-header-create-button">
          <ButtonDropdown
            variant="primary"
            items={CREATE_ACCOUNT_ITEMS}
          >
            创建Amazon Web Services账户
          </ButtonDropdown>
        </div>
        {isDropDownOpen && (
        <div className="mobile-header-dropdown">
          {NAVIGATION_ITEMS_MOBILE.map((item) => {
            if (item.href) {
              return (
                <div className="mobile-header-dropdown-item-wrapper">
                  <a
                    className="mobile-header-dropdown-item"
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.text}
                  </a>
                </div>
              );
            }
            if (item.children) {
              return (
                <div
                  key={item.id}
                >
                  <div className="mobile-header-dropdown-item-wrapper">
                    <div
                      onClick={() => onClickDropDownItem(item.id)}
                      onKeyDown={() => {}}
                      className="mobile-header-dropdown-item"
                    >
                      {item.id === 'profile' ? (
                        <div className="profile-wrapper">
                          <img src={userInfo && userInfo.avatarUrl} alt="" className="profile-icon" />
                          <strong className="profile-text">
                            {`欢迎 ${userInfo ? userInfo.nickName : ''}`}
                          </strong>
                        </div>
                      )
                        : (
                          <strong className="mobile-header-dropdown-item-title">
                            {item.text}
                          </strong>
                        ) }

                      {!item.children ? null
                        : isSectionOpen[item.id] ? <Icon name="caret-up-filled" /> : <Icon name="caret-down-filled" /> }

                    </div>
                    {isSectionOpen[item.id] && item.children && item.children.map((child) => (
                      <a
                        className="mobile-header-dropdown-item-child"
                        href={child.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={child.id}
                      >
                        {child.text}
                      </a>

                    ))}
                  </div>
                </div>
              );
            }
            return <> </>;
          })}
        </div>
        )}
      </div>
    </div>

  );
};

export default MobileHeader;
