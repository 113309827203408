/* eslint-disable max-len */
import axios from 'axios';

// import MockAdapter from 'axios-mock-adapter';
import { ErrorCode, ErrorCase } from '../constants/error';

import { getBackendURL, SKIP_ERROR_PAGES_ENDPOINT_URLS } from './constant';

const axiosInstance = axios.create({
  baseURL: getBackendURL(),
  timeout: 20000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'x-origin-path': window.location.pathname,
    'x-device': navigator.userAgent && navigator.userAgent.indexOf('miniProgram') > -1 ? 'miniprogram' : 'browser',
  },
});

axiosInstance.interceptors.request.use((config) => config, (error) => Promise.reject(error));

export const setupResponseInterceptor = (navigate: any) => {
  axiosInstance.interceptors.response.use((response) => {
    const { data } = response;
    return data;
  }, (error) => {
    if (SKIP_ERROR_PAGES_ENDPOINT_URLS.indexOf(error?.config.url) !== -1) {
      return error.response.data;
    }
    const code: keyof typeof ErrorCode = error?.response?.status;

    if (code) {
      if (code === ErrorCase.NO_PERMISSION) {
        const { data } = error.response;
        if (typeof data === 'object' && 'location' in data) {
          const { location } = data;
          setTimeout(() => {
            window.location.href = location;
          }, 0);
        }
      } else {
        setTimeout(() => {
          navigate(`/error/${ErrorCode[code]}`);
        }, 0);
      }
      return Promise.reject(new Error(JSON.stringify({
        error,
        statusCode: code,
      })));
    }
    return Promise.reject(new Error(JSON.stringify({
      error,
      statusCode: code,
    })));
  });
};

// FOR TESTING ONLY
// const mock = new MockAdapter(axiosInstance);

// mock.onPost('/uc/user_profile/get_user_profile').reply(200, {
//   requestId: '77922465-763c-425e-acf2-a72a5a9d577a',
//   responseCode: 'SUCCESS',
//   detailedResponseCode: 'SUCCESS',
//   responseMessage: 'SUCCESS',
//   email: 'x*******@amazon.com',
//   mobilePhoneNumber: '150****9600',
//   fullName: '小明22小明小明aaaa2aaaa2aaaa2aaaa2aaaa',
//   nickName: 'nickName',
//   avatarUrl: 'https://account-beta.amazoncloud.cn/config/images/user-photo-10.png',
//   selfIntro: '小明小明aaaa2aaaa2aaaa2aaaa2aaaa2aaaa222小明小明aaaa2aaaa2aaaa2aaaa2aaaa2aaaa222小明小明aaaa2aaaa2aaaa2aaaa2aaaa2aaaa222小明小明aaaa2aaaa2aaaa2aaaa2aaaa2aaaa222小明小明aaaa2aaaa2aaaa2aaaa2aaaa2aaaa222小明小明aaaa2aaaa2aaaa2aaaa2aaaa2aaaa222',
//   company: {
//     companyName: 'ccc',
//     postalCode: '222222',
//     city: '北京',
//     province: '北京',
//     jobRole: 'IT Technical Manager',
//     industry: 'Manufacturing',
//     address: '北京市朝阳区a',
//     companySize: '5X100_999_Employees',
//     website: 'http',
//   },
// });

// mock.onPost('uc/user_profile/update_user_profile').reply(200, {
//   requestId: '29d061b2-23e6-41f9-ae03-9ca99398d912',
//   responseCode: 'SUCCESS',
//   detailedResponseCode: 'SUCCESS',
//   responseMessage: 'SUCCESS',
// });

export default axiosInstance;
