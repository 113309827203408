import
{
  constructContext, createLog,
  batchAllRequest,
  publishRecorded, createMetric,
}
  from '@amzn/awslog-amonitor';

import { REPORT_URL } from '../api/constant';

export interface IMapping {
  name: string;
  value: any;
}

export const MetricUnit = {
  Count: 'Count',
  Milliseconds: 'Milliseconds',
};

export const CURRENT_CONTEXT = constructContext(
  'User_Center',
  'h98cw2',
  '',
);

export const batchedRequests = batchAllRequest(publishRecorded, 'logInfo', 3000, 6);
/**
   * logPayload creates a clientLogger payload consisting of a context, metrics, and logs,
   * and sends a request to clientLogger to log the event
   */
const logPayload = (url: string, payload: any, context: any) => {
  batchedRequests(url, payload, context);
};

export const log = (level: string, message: string) => {
  if (!CURRENT_CONTEXT) {
    console.error('Logger has not instantiated. Unable to log');
    return;
  }
  const logMessage = createLog(level.toString(), message);
  logPayload(
    REPORT_URL,
    {
      logs: [logMessage],
      metrics: [],
    },
    CURRENT_CONTEXT,
  );
};

export const metric = (
  dimensions: IMapping[],
  name: string,
  value: any,
  additionalInformation?: IMapping[],
  unit?: string,
) => {
  if (!CURRENT_CONTEXT) {
    console.error('Logger has not instantiated. Unable to log metric');
    return;
  }

  const logMetric = createMetric(
    dimensions,
    name,
    unit ? unit.toString() : MetricUnit.Count.toString(),
    value,
    additionalInformation,
  );

  logPayload(
    REPORT_URL,
    {
      logs: [],
      metrics: [logMetric],
    },
    CURRENT_CONTEXT,
  );
};
