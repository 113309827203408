import React, { useState } from 'react';
import {
  Container, Header, Button, Checkbox, SpaceBetween, Box, Link,
} from '@amzn/awsui-components-react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateUserAgreementAsync, setIsEditUserAgreementStatus } from '../../../app/infoReducer';
import { log, metric } from '../../../utils/monitor';
import type { UserAgreementProps } from '../../../api/types';

export const marketingTouchPolicyLabel = (
  <>
    是，我希望
    <Link href="https://aws.amazon.com/cn/legal/marketingentities/"> Amazon Web Services </Link>
    (亚马逊云科技) 通过电子邮件、邮件或电话与我分享关于亚马逊云科技和相关产品的最新消息。
    您可以按照收到的通信中的说明，随时取消接收亚马逊云科技新闻和优惠。
    亚马逊云科技将按照
    <Link href="https://webinar.amazoncloud.cn/legal/privacypolicy/AWS_Privacy_Notice__Chinese_Simplified_Translation.pdf"> 亚马逊云科技隐私声明 </Link>
    的规定来处理您的信息。
  </>
);

interface IProps {
  updateEditStatusFn: (status: boolean) => void;
  userAgreements: UserAgreementProps;
}

const EditUserAgreement = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { updateEditStatusFn, userAgreements } = props;
  const [newUserAgreements, updateUserAgreement] = useState(userAgreements);
  const [updateLoading, setUpdateLoading] = useState(false);

  const onCheckboxChange = (policy: string) => {
    updateUserAgreement((prev) => ({ ...prev, [policy]: !prev[policy] }));
  };

  const onClickSubmit = async () => {
    setUpdateLoading(true);
    try {
      await dispatch(updateUserAgreementAsync(newUserAgreements));
      updateEditStatusFn(false);
      // todo add log
      // log('INFO', `editUserAgreement ${JSON.stringify(newUserAgreements)}`);
      metric([], 'Edit UserAgreement Success', 1, [], 'Count');
    } catch (err) {
      console.error('🚀 ~ file: index.tsx:35 ~ onClickSubmit ~ err:', err);
    } finally {
      setUpdateLoading(false);
    }
  };

  const onClickCancel = () => {
    updateEditStatusFn(false);
  };

  return (
    <Container
      header={(
        <Header>
          用户协议
        </Header>
            )}
      footer={(
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={onClickCancel}
              data-testid="edit-user-agreement-cancel-button"
            >
              取消
            </Button>
            <Button
              variant="primary"
              loading={updateLoading}
              onClick={onClickSubmit}
              data-testid="edit-user-agreement-confirm-button"
            >
              确定
            </Button>
          </SpaceBetween>
        </Box>
            )}
    >
      <div className="edit-user-agreement">
        <Checkbox
          checked={newUserAgreements.marketingTouchPolicy}
          onChange={() => onCheckboxChange('marketingTouchPolicy')}
          data-testid="edit-user-agreement-marketingTouchPolicy"
        >
          {marketingTouchPolicyLabel}
        </Checkbox>
      </div>
    </Container>
  );
};

export default EditUserAgreement;
