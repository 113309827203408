/* eslint-disable max-len */
export interface InputErrorValidationType {
  isDigit?: boolean;
  minLength?: number;
  maxLength?: number;
  isPhoneNumber?: boolean;
  isEmail?: boolean;
  isOtp?: boolean;
}

export const validateIsDigit = (input: string) => {
  const regex = /^\d+$/;
  return regex.test(input);
};

export const validateIsPotentialXSSCharacterPattern = (input: string) => {
  // check input not contain <, >, ", \, %, \t, \n, |
  const regex = /^[^<>"\\%\t\n|]+$/;
  return regex.test(input);
};

export const validatePhoneNumber = (input: string) => {
  const phoneNumberPattern = /^((\+?0?)|((\+|0)?86)?)1[3456789]\d{9}$/;
  return phoneNumberPattern.test(input);
};

export const validateEmail = (input: string) => {
  const emailPatter = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPatter.test(input);
};

export const getValidationErrorState = (
  validationError: any,
  attribute: string,
  value: string,
  type: InputErrorValidationType,
) => {
  let newState = { ...validationError };
  const {
    minLength = 0, maxLength = 1000, isDigit = false, isPhoneNumber = false, isEmail = false, isOtp = false,
  } = type;
  if (isPhoneNumber) {
    newState = { ...validationError, [attribute]: validatePhoneNumber(value) ? '' : '手机号码格式有误' };
    return newState;
  }
  if (isEmail) {
    newState = { ...validationError, [attribute]: validateEmail(value) ? '' : '邮箱格式有误' };
    return newState;
  }
  if (isOtp) {
    newState = { ...validationError, [attribute]: (validateIsDigit(value) && value.length === minLength) ? '' : `请输入${minLength}位验证码` };
    return newState;
  }
  if (isDigit) {
    newState = { ...validationError, [attribute]: validateIsDigit(value) ? '' : '请输入数字' };
  }
  if (!isDigit) {
    newState = { ...validationError, [attribute]: validateIsPotentialXSSCharacterPattern(value) ? '' : '输入内容包含非法字符' };
  }
  if (value.length < minLength || value.length > maxLength) {
    if (isDigit) {
      newState = { ...validationError, [attribute]: minLength === maxLength ? `长度必须为${minLength}位数字` : `长度必须在${minLength}-${maxLength}位数字之间` };
    } else {
      newState = { ...validationError, [attribute]: minLength === maxLength ? `长度必须为${minLength}个字符` : `长度必须在${minLength}-${maxLength}个字符之间` };
    }
  }
  return newState;
};
