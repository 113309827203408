import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Header, Input, FormField, SpaceBetween, Button, Select,
} from '@amzn/awsui-components-react';

import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import type { CompanyInfoProps, SelectOptionProps } from '../../../api/types';
import { updateCompanyInfoAsync, setIsEditCompanyStatus } from '../../../app/infoReducer';
import { locale } from '../../../constants/config';
import { InfoDataField } from '../../basicInfo/companyInfo/type';
import { defaultCompanyFormValidationMap } from '../../../constants/error';
import type { InputErrorValidationType } from '../../../utils/inputValidation';
import {
  getValidationErrorState,
} from '../../../utils/inputValidation';
import { metric } from '../../../utils/monitor';

interface IProps {
  updateEditStatusFn: (status: boolean) => void;
  companyInfo: CompanyInfoProps;
}

const EditCompanyInfo = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { configResponse } = useAppSelector((state) => state?.info);
  const { updateEditStatusFn, companyInfo } = props;
  const [companyInfoData, setCompanyInfoData] = useState<any>(companyInfo);
  const [companyInfoDataFormatted, setCompanyInfoDataFormatted] = useState(companyInfo);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [jobRoleSelectData, setJobRoleSelectData] = useState<SelectOptionProps[]>();
  const [companySizeSelectData, setCompanySizeSelectData] = useState<SelectOptionProps[]>();
  const [industrySelectData, setIndustrySelectData] = useState<SelectOptionProps[]>();
  const [provinceCityRawData, setProvinceCityRawData] = useState<any>();
  const [citySelectData, setCitySelectData] = useState<SelectOptionProps[]>();
  const [provinceSelectData, setProvinceSelectData] = useState<SelectOptionProps[]>();
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [validationError, setValidationError] = useState(defaultCompanyFormValidationMap);

  const checkHasValidationError = (state: any) => Object.values(state).some((value) => value !== '');
  const validateInput = (attribute: string, value: string, type: InputErrorValidationType) => {
    if (value === '') {
      const newState = { ...validationError, [attribute]: '' };
      setValidationError(newState);
      setIsInputError(checkHasValidationError(newState));
      return;
    }
    const newState = getValidationErrorState(validationError, attribute, value, type);
    setValidationError(newState);
    setIsInputError(checkHasValidationError(newState));
  };

  const onChange = (attribute: string, value: string, type: InputErrorValidationType) => {
    const newState = { ...companyInfoData, [attribute]: value };
    setCompanyInfoData(newState);
    const formattedNewState = { ...companyInfoDataFormatted, [attribute]: value };
    setCompanyInfoDataFormatted(formattedNewState);
    validateInput(attribute, value, type);
  };

  const onChangeSelect = (attribute: string, value: any) => {
    const newState = { ...companyInfoData, [attribute]: value };
    if (attribute === InfoDataField.PROVINCE) {
      newState[InfoDataField.CITY] = '';
    }
    setCompanyInfoData(newState);
    const { value: v } = value;
    const formattedNewState = { ...companyInfoDataFormatted, [attribute]: v };
    setCompanyInfoDataFormatted(formattedNewState);
  };

  const convertRawJsonDataIntoSelect = (data: any) => {
    if (!data) return [];
    const converted = Object.keys(data).map((key) => ({
      label: data[key][`${locale}`],
      value: key,
    }));
    const defaultOption = {
      label: '选择',
      value: '',
    };
    return [defaultOption, ...converted];
  };

  const convertCityRawJsonDataIntoSelect = (data: any) => {
    const selectedProvinceValue = companyInfoDataFormatted[InfoDataField.PROVINCE];
    if (typeof data === 'object' && selectedProvinceValue in data) {
      const provinceObj = data[selectedProvinceValue];
      const { codes } = provinceObj;
      const convertedCityData = convertRawJsonDataIntoSelect(codes);
      return { codes, convertedCityData };
    }
    return { codes: {}, convertedCityData: undefined };
  };

  const onClickSubmit = async () => {
    try {
      setUpdateLoading(true);
      await Promise.all([
        dispatch(updateCompanyInfoAsync(companyInfoDataFormatted)),
        dispatch(setIsEditCompanyStatus(false)),
      ]);
      setUpdateLoading(false);
      updateEditStatusFn(false);
      metric([], 'Edit CompanyInfo Success', 1, [], 'Count');
    } catch (err) {
      console.error('🚀 ~ file: index.tsx:32 ~ onClickSubmit ~ err:', err);
    }
  };

  const setSelectedOptionFn = useCallback((attribute: string, data: any) => {
    const value = companyInfo[`${attribute}`];
    if (data && typeof data === 'object' && value in data) {
      const option = {
        label: data[value][`${locale}`],
        value,
      };
      setCompanyInfoData((prevState: any) => ({
        ...prevState,
        [attribute]: option,
      }));
    }
  }, []);

  const onClickCancel = () => {
    updateEditStatusFn(false);
    dispatch(setIsEditCompanyStatus(false));
  };

  useEffect(() => {
    const jobRoleData = configResponse[0];
    setJobRoleSelectData(convertRawJsonDataIntoSelect(jobRoleData));
    setSelectedOptionFn(InfoDataField.JOB_ROLE, jobRoleData);

    const provinceCityData = configResponse[1];
    setProvinceCityRawData(provinceCityData);
    setProvinceSelectData(convertRawJsonDataIntoSelect(provinceCityData));
    setSelectedOptionFn(InfoDataField.PROVINCE, provinceCityData);

    const companySizeData = configResponse[2];
    setCompanySizeSelectData(convertRawJsonDataIntoSelect(companySizeData));
    setSelectedOptionFn(InfoDataField.COMPANY_SIZE, companySizeData);

    const industryData = configResponse[3];
    setIndustrySelectData(convertRawJsonDataIntoSelect(industryData));
    setSelectedOptionFn(InfoDataField.INDUSTRY, industryData);

    // const iRData = configResponse[4];
    // setInformationRelationshipData(convertRawJsonDataIntoSelect(iRData));
    // setSelectedOptionFn(InfoDataField.INFORMATION_RELATIONSHIP, iRData);
  }, [setSelectedOptionFn]);

  useEffect(() => {
    if (provinceCityRawData) {
      const { codes, convertedCityData } = convertCityRawJsonDataIntoSelect(provinceCityRawData);
      if (Object.keys(codes).length > 0 && convertedCityData && Object.keys(convertedCityData).length > 0) {
        setCitySelectData(convertedCityData);
        setSelectedOptionFn(InfoDataField.CITY, codes);
      }
    }
  }, [provinceCityRawData, companyInfoData[InfoDataField.PROVINCE]]);

  return (
    <div className="edit-company-info-page">
      <Container
        header={(
          <Header
            variant="h2"
          >
            公司信息
          </Header>
              )}
        footer={(
          <div className="edit-company-button">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => onClickCancel()}
                data-testid="edit-company-info-cancel-button"
              >
                取消
              </Button>
              <Button
                disabled={isInputError}
                variant="primary"
                loading={updateLoading}
                onClick={() => onClickSubmit()}
                data-testid="edit-company-info-confirm-button"
              >
                确定
              </Button>
            </SpaceBetween>
          </div>
            )}
      >

        <SpaceBetween direction="vertical" size="l">
          <FormField
            label="公司名称"
            errorText={validationError[InfoDataField.COMPANY_NAME]}
          >
            <Input
              data-testid="edit-company-info-company-name-input"
              value={companyInfoData[InfoDataField.COMPANY_NAME]}
              onChange={({ detail: { value } }) => onChange(InfoDataField.COMPANY_NAME, value, {
                maxLength: 100,
              })}
            />
          </FormField>
          <FormField
            label="工作职务"
          >
            <Select
              data-testid="edit-company-info-job-role-select"
              selectedOption={companyInfoData[InfoDataField.JOB_ROLE]}
              onChange={({ detail: { selectedOption } }) => onChangeSelect(InfoDataField.JOB_ROLE, selectedOption)}
              options={jobRoleSelectData}
              placeholder="选择"
            />
          </FormField>
          <FormField
            label="公司规模"
          >
            <Select
              data-testid="edit-company-info-company-size-select"
              selectedOption={companyInfoData[InfoDataField.COMPANY_SIZE]}
              onChange={({ detail: { selectedOption } }) => onChangeSelect(InfoDataField.COMPANY_SIZE, selectedOption)}
              options={companySizeSelectData}
              placeholder="选择"
            />
          </FormField>
          <FormField
            label="所属行业"
          >
            <Select
              data-testid="edit-company-info-industry-select"
              selectedOption={companyInfoData[InfoDataField.INDUSTRY]}
              onChange={({ detail: { selectedOption } }) => onChangeSelect(InfoDataField.INDUSTRY, selectedOption)}
              options={industrySelectData}
              placeholder="选择"
            />
          </FormField>
          <div className="input-flex-line">
            <div>
              <FormField
                label="省份"
              >
                <Select
                  selectedOption={companyInfoData[InfoDataField.PROVINCE]}
                  onChange={({ detail: { selectedOption } }) => onChangeSelect(InfoDataField.PROVINCE, selectedOption)}
                  options={provinceSelectData}
                  placeholder="选择"
                  data-testid="edit-company-info-province-select"
                />
              </FormField>
            </div>
            <div>
              {companyInfoData[InfoDataField.PROVINCE]
            && (
            <FormField
              label="城市"
            >
              <Select
                selectedOption={companyInfoData[InfoDataField.CITY]}
                onChange={({ detail: { selectedOption } }) => onChangeSelect(InfoDataField.CITY, selectedOption)}
                options={citySelectData}
                placeholder="选择"
                data-testid="edit-company-info-city-select"
              />
            </FormField>
            )}
            </div>
          </div>
          <FormField
            label="公司通信地址"
            errorText={validationError[InfoDataField.ADDRESS]}
          >
            <Input
              value={companyInfoData[InfoDataField.ADDRESS]}
              onChange={({ detail: { value } }) => onChange(InfoDataField.ADDRESS, value, {
                maxLength: 300,
              })}
              data-testid="edit-company-info-company-address-input"
            />
          </FormField>

          <FormField
            label="邮政编码"
            errorText={validationError[InfoDataField.POSTAL_CODE]}
          >
            <Input
              value={companyInfoData[InfoDataField.POSTAL_CODE]}
              onChange={({ detail: { value } }) => onChange(InfoDataField.POSTAL_CODE, value, {
                minLength: 6,
                maxLength: 6,
                isDigit: true,
              })}
              data-testid="edit-company-info-postal-code-input"
            />
          </FormField>
          <FormField
            label="公司网站"
            errorText={validationError[InfoDataField.WEBSITE]}
          >
            <Input
              value={companyInfoData[InfoDataField.WEBSITE]}
              onChange={({ detail: { value } }) => onChange(InfoDataField.WEBSITE, value, {
                maxLength: 300,
              })}
              data-testid="edit-company-info-website-input"
            />
          </FormField>
        </SpaceBetween>

      </Container>
    </div>
  );
};

export default EditCompanyInfo;
