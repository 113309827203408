import React, { useEffect } from 'react';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';

import BasicInfo from '../basicInfo';
import SecurityInfoNew from '../securityInfoNew';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getConfigAsync } from '../../app/infoReducer';
import ErrorBanner from '../error';
import DefaultPage from '../defaultPage';

const ContentRoutes = () => {
  const dispatch = useAppDispatch();
  const isErrorPage = window.location.href.indexOf('error') !== -1;
  const { stage } = useAppSelector((state) => state?.info);

  useEffect(() => {
    if (!isErrorPage) {
      dispatch(getConfigAsync());
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate replace to="/my-info/basic" />}
      />
      <Route
        path="/my-info"
        element={<Navigate replace to="/my-info/basic" />}
      />
      <Route
        path="/security-info"
        element={<Navigate replace to="/security-info/account" />}
      />
      <Route path="/my-info/basic" element={<BasicInfo />} />
      <Route path="/security-info/account" element={<SecurityInfoNew />} />
      <Route path="/error/:errorType" element={<ErrorBanner />} />
      <Route path="*" element={<DefaultPage />} />
    </Routes>
  );
};

export default ContentRoutes;
