import React, { useState } from 'react';
import {
  Header, Button, Container, Checkbox, Link,
} from '@amzn/awsui-components-react';

import EditUserAgreement, { marketingTouchPolicyLabel } from '../../editBasicInfo/editUserAgreement';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setIsEditUserAgreementStatus } from '../../../app/infoReducer';
import { log, metric } from '../../../utils/monitor';

const UserAgreement = () => {
  const dispatch = useAppDispatch();
  const { userAgreements } = useAppSelector((state) => state?.info);
  const [isEditUserAgreement, setIsEditUserAgreement] = useState<boolean>(false);

  // It triggers the UserAgreement re-rendering with isEditUserAgreement set to true
  const onClickEditUserAgreementButton = () => {
    // set isEditUserAgreement to true
    setIsEditUserAgreement((prevState) => !prevState);
    dispatch(setIsEditUserAgreementStatus(true));
    // todo add log
    // log('INFO', `editUserAgreement ${JSON.stringify(userAgreements)}`);
    metric([], 'Click Edit UserAgreement Button', 1, [], 'Count');
  };

  // return to non-editable state
  const onUpdateEditStatusFn = (status: boolean) => {
    setIsEditUserAgreement(status);
    dispatch(setIsEditUserAgreementStatus(status));
  };

  return (
    isEditUserAgreement
      ? (
        <EditUserAgreement
          updateEditStatusFn={onUpdateEditStatusFn}
          userAgreements={userAgreements}
        />
      )
      : (
        <Container
          header={(
            <Header actions={(
              <Button
                data-testid="edit-user-agreement-button"
                onClick={() => onClickEditUserAgreementButton()}
              >
                编辑
              </Button>
                        )}
            >
              用户协议
            </Header>
                    )}
        >
          <div className="user-agreement-container">
            <Checkbox
              data-testid="user-agreement-marketingTouchPolicy"
              checked={userAgreements.marketingTouchPolicy}
              readOnly
            >
              {marketingTouchPolicyLabel}
            </Checkbox>
          </div>
        </Container>
      )
  );
};

export default UserAgreement;
