import React from 'react';

import './index.scss';
import BeiAnIcon from '../../../public/beian.png';

const Footer = () => {
  const onHref = (href: string) => {
    window.open(href);
  };
  const currentYear = new Date().getFullYear();

  return (
    <div id="footer-bar">
      <div className="website-footer">
        <div className="content-wrapper">
          <span>
            {`© ${currentYear}, Amazon Web Services, Inc. 或其关联公司。保留所有权利。`}
          </span>
          <button
            type="button"
            className="button-wrapper"
            onClick={() => onHref('https://beian.mit.gov.cn/')}
          >
            京ICP备15049550号-13
          </button>
          <button
            type="button"
            className="button-wrapper"
            onClick={() => onHref('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052141')}
          >
            <img src={BeiAnIcon} alt="" className="beian-img" />
            京公网安备11010502052141号
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
