import React, { useState, useEffect } from 'react';
import {
  Container, Header, Input, FormField, SpaceBetween, Button, Textarea, Modal, Box,
} from '@amzn/awsui-components-react';

import './index.scss';
import { useAppDispatch } from '../../../app/hooks';
import type { IndividualInfoProps } from '../../../api/types';
import { updateUserInfoAsync, setIsEditPersonalStatus } from '../../../app/infoReducer';
import { getValidationErrorState } from '../../../utils/inputValidation';
import type { InputErrorValidationType } from '../../../utils/inputValidation';
import { InfoDataField } from '../../basicInfo/companyInfo/type';
import { avatarUrls } from '../../../api/constant';
import { defaultUserInfoFormValidationMap, OtpErrorCode } from '../../../constants/error';
import { metric } from '../../../utils/monitor';
import { sendOtpApi, verifyOtpApi } from '../../../api/api';

interface IProps {
  updateEditStatusFn: (status: boolean) => void;
  userInfo: IndividualInfoProps;
  defaultAvatar: string;
}

const EditPersonalInfo = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { updateEditStatusFn, userInfo, defaultAvatar } = props;
  const [userInfoData, setUserInfoData] = useState<IndividualInfoProps>(
    {
      ...userInfo,
      avatarUrl: defaultAvatar,
    },
  );
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState(defaultUserInfoFormValidationMap);
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [isUpdateContactPhoneNumberOkay, setIsUpdateContactPhoneNumberOkay] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<string>(defaultAvatar);
  const [isEditContactPhoneNumber, setIsContactPhoneNumber] = useState<boolean>(false);
  const [newContactPhoneNumber, setNewContactPhoneNumber] = useState<string>('');
  const [otpCode, setOtpCode] = useState<string>('');
  const [challengeId, setChallengeId] = useState<string>('');
  const [isCountingDown, setIsCountingDown] = useState<boolean>(false);
  const [countDownNumber, setCountDownNumber] = useState<number>(60);
  const [isVerifyOtpCodeLoading, setIsVerifyOtpCodeLoading] = useState<boolean>(false);

  const dialog = document.querySelectorAll('[class*="awsui_dialog"]');
  if (dialog.length > 0) {
    dialog.forEach((item) => {
      const itemEle = item as HTMLElement;
      itemEle.style.maxWidth = '450px';
    });
  }

  const onClickSubmit = async () => {
    try {
      setUpdateLoading(true);
      const copyUserInfoData = { ...userInfoData };
      delete copyUserInfoData[InfoDataField.CONTACT_PHONE_NUMBER];
      await Promise.all([
        dispatch(updateUserInfoAsync(copyUserInfoData)),
        dispatch(setIsEditPersonalStatus(false)),
      ]);
      setUpdateLoading(false);
      updateEditStatusFn(false);
      metric([], 'Edit PersonalInfo Success', 1, [], 'Count');
    } catch (err) {
      console.error('🚀 ~ file: index.tsx:32 ~ onClickSubmit ~ err:', err);
    }
  };

  const onClickCancel = () => {
    updateEditStatusFn(false);
    dispatch(setIsEditPersonalStatus(false));
  };

  const checkHasValidationError = (state: any) => Object.entries(state).some(([key, value]) => key
   !== InfoDataField.CONTACT_PHONE_NUMBER && key !== InfoDataField.OTP_CODE && value !== '');

  const validateInput = (attribute: string, value: string, type: InputErrorValidationType) => {
    if (value === '') {
      const newState = { ...validationError, [attribute]: '' };
      setValidationError(newState);
      setIsInputError(checkHasValidationError(newState));
      return;
    }
    const newState = getValidationErrorState(validationError, attribute, value, type);
    setValidationError(newState);
    setIsInputError(checkHasValidationError(newState));
  };

  const onChange = (attribute: string, value: string, type: InputErrorValidationType) => {
    if (attribute === InfoDataField.CONTACT_PHONE_NUMBER) {
      setNewContactPhoneNumber(value);
    } else if (attribute === InfoDataField.OTP_CODE) {
      setOtpCode(value);
    } else {
      const newState = { ...userInfoData, [attribute]: value };
      setUserInfoData(newState);
    }
    validateInput(attribute, value, type);
  };

  const handleCountDown = () => {
    if (!isCountingDown) {
      setIsCountingDown(true);
    }
  };

  const onClickGetOtpCode = async () => {
    handleCountDown();
    const data = {
      phoneNumber: newContactPhoneNumber,
    };

    try {
      const res = await sendOtpApi(data);
      if (res?.challengeId) {
        setChallengeId(res.challengeId);
      }
      if (res?.detailedResponseCode === OtpErrorCode.OTP_SEND_INTERVAL_LIMITED) {
        const newState = { ...validationError, [InfoDataField.CONTACT_PHONE_NUMBER]: '操作过于频繁，请稍后再试' };
        setValidationError(newState);
      }
    } catch (error) {
      console.error('🚀 ~ file: index.tsx:116 ~ onClickGetOtpCode ~ error', error);
    }
  };

  const onClickConfirmUpdateContactPhoneNumber = async () => {
    const data = {
      contactPhoneNumber: newContactPhoneNumber,
      otpCode,
      challengeId,
    };
    try {
      setIsVerifyOtpCodeLoading(true);
      const res = await verifyOtpApi(data);
      if (res.responseCode === 'SUCCESS') {
        setUserInfoData((prev) => ({ ...prev, contactPhoneNumber: newContactPhoneNumber }));
        setIsContactPhoneNumber(false);
      } else if (res?.detailedResponseCode === OtpErrorCode.OTP_CODE_NOT_MATCH
        || res?.detailedResponseCode === OtpErrorCode.OTP_PHONE_NOT_MATCH
        || res?.detailedResponseCode === OtpErrorCode.INVALID_INPUT) {
        const newState = { ...validationError, [InfoDataField.OTP_CODE]: '验证码有误' };
        setValidationError(newState);
      } else if (res?.detailedResponseCode === OtpErrorCode.OTP_RETRY_LIMITS) {
        const newState = { ...validationError, [InfoDataField.OTP_CODE]: '验证码错误次数过多，请稍后再试' };
        setValidationError(newState);
      }
      setIsVerifyOtpCodeLoading(false);
    } catch (error) {
      console.error('🚀 ~ file: index.tsx:128 ~ onClickConfirmUpdateContactPhoneNumber ~ error', error);
      setIsVerifyOtpCodeLoading(false);
    }
  };

  const onCancelUpdatePhoneNumberModal = () => {
    const newState = { ...validationError, [InfoDataField.CONTACT_PHONE_NUMBER]: '', [InfoDataField.OTP_CODE]: '' };
    setValidationError(newState);
    setNewContactPhoneNumber('');
    setOtpCode('');
    setIsCountingDown(false);
    setIsContactPhoneNumber(false);
  };

  useEffect(() => {
    let timer: any;
    if (isCountingDown) {
      timer = setInterval(() => {
        if (countDownNumber === 0) {
          clearInterval(timer);
          setIsCountingDown(false);
          setCountDownNumber(60);
        } else {
          setCountDownNumber((prev) => prev - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isCountingDown, countDownNumber]);

  useEffect(() => {
    if (newContactPhoneNumber !== '' && otpCode !== ''
    && validationError[InfoDataField.CONTACT_PHONE_NUMBER] === '' && validationError[InfoDataField.OTP_CODE] === '') {
      setIsUpdateContactPhoneNumberOkay(true);
    } else {
      setIsUpdateContactPhoneNumberOkay(false);
    }
  }, [newContactPhoneNumber, otpCode, validationError]);

  return (
    <div className="edit-personal-info-page">
      <Modal
        onDismiss={onCancelUpdatePhoneNumberModal}
        visible={isEditContactPhoneNumber}
        footer={(
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid="edit-contact-phone-number-cancel-button"
                variant="link"
                onClick={onCancelUpdatePhoneNumberModal}
              >
                取消
              </Button>
              <Button
                variant="primary"
                disabled={!isUpdateContactPhoneNumberOkay}
                onClick={onClickConfirmUpdateContactPhoneNumber}
                loading={isVerifyOtpCodeLoading}
                data-testid="edit-contact-phone-number-confirm-button"
              >
                确认
              </Button>
            </SpaceBetween>
          </Box>
                )}
        header="修改联系手机号码"
      >
        <SpaceBetween size="l">
          <FormField
            label="新的手机号码"
            description="请提供11位以数字1开头的中国大陆手机号码"
            errorText={validationError[InfoDataField.CONTACT_PHONE_NUMBER]}
          >
            <div className="modal-line">
              <div className="modal-line-input">
                <Input
                  onChange={({ detail: { value } }) => onChange(InfoDataField.CONTACT_PHONE_NUMBER, value, {
                    isPhoneNumber: true,
                  })}
                  value={newContactPhoneNumber}
                  data-testid="new-contact-phone-number-input"
                />
              </div>
              <div className="modal-line-button">
                <Button
                  data-testid="get-otp-code-button"
                  onClick={onClickGetOtpCode}
                  disabled={isCountingDown || newContactPhoneNumber === ''
                  || validationError[InfoDataField.CONTACT_PHONE_NUMBER] !== ''}
                >
                  {isCountingDown ? `重发（${countDownNumber}秒）` : '获取验证码'}
                </Button>
              </div>
            </div>
          </FormField>
          <FormField
            label="输入验证码"
            description="输入您在手机上收到的6位验证码"
            errorText={validationError[InfoDataField.OTP_CODE]}
          >
            <Input
              onChange={({ detail: { value } }) => onChange(InfoDataField.OTP_CODE, value, {
                isOtp: true,
                minLength: 6,
                maxLength: 6,
              })}
              value={otpCode}
              data-testid="otp-code-input"
            />
          </FormField>
        </SpaceBetween>
      </Modal>
      <Container
        header={(
          <Header
            variant="h2"
          >
            个人信息
          </Header>
          )}
        footer={(
          <div className="edit-personal-button">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => onClickCancel()}
                data-testid="edit-personal-info-cancel-button"
              >
                取消
              </Button>
              <Button
                disabled={isInputError}
                loading={updateLoading}
                variant="primary"
                onClick={() => onClickSubmit()}
                data-testid="edit-personal-info-confirm-button"
              >
                确定
              </Button>
            </SpaceBetween>
          </div>
          )}
      >

        <SpaceBetween direction="vertical" size="l">
          <div className="top-avatar-container">
            <div>用户头像</div>
            <div className="avatar-container">
              {avatarUrls.map((avatarUrl, index) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <img
                  onKeyDown={() => {}}
                  onClick={() => {
                    setSelectedImg(avatarUrl);
                    onChange(InfoDataField.AVATAR_URL, avatarUrl, {
                      maxLength: 100,
                    });
                  }}
                  key={avatarUrl}
                  className={selectedImg === avatarUrl ? 'selected-avatar' : 'avatars'}
                  src={avatarUrl}
                  alt="avatar"
                  data-testid={`edit-personal-info-avatar-img-${index + 1}`}
                />
              ))}
            </div>
          </div>
          <div className="input-flex-line">
            <div>
              <FormField
                label="昵称"
                errorText={validationError[InfoDataField.NICK_NAME]}
              >
                <Input
                  value={userInfoData?.nickName}
                  onChange={({ detail: { value } }) => onChange(InfoDataField.NICK_NAME, value, {
                    maxLength: 100,
                  })}
                  data-testid="edit-personal-info-nick-name-input"
                />
              </FormField>
            </div>
            <div>
              <FormField
                label="姓名"
                errorText={validationError[InfoDataField.FULL_NAME]}
              >
                <Input
                  value={userInfoData?.fullName}
                  onChange={({ detail: { value } }) => onChange(
                    InfoDataField.FULL_NAME,
                    value,
                    {
                      maxLength: 100,
                    },
                  )}
                  data-testid="edit-personal-info-full-name-input"
                />
              </FormField>
            </div>
          </div>
          <div className="input-flex-line">
            <div>
              <FormField
                label="联系手机号码"
              >
                <div className="flex-line">
                  <div className="flex-line-input">
                    <Input
                      value={userInfoData?.contactPhoneNumber || ''}
                      // onChange={({ detail: { value } }) => onChange(InfoDataField.CONTACT_PHONE_NUMBER, value, {
                      //   maxLength: 100,
                      // })}
                      data-testid="edit-personal-info-contact-number-input"
                      disabled
                    />
                  </div>
                  <div className="flex-line-button">
                    <Button onClick={() => setIsContactPhoneNumber(true)} className="button">修改</Button>
                  </div>
                </div>
              </FormField>
            </div>
            <div>
              <FormField
                label="联系邮箱"
                errorText={validationError[InfoDataField.CONTACT_EMAIL]}
              >
                <Input
                  value={userInfoData?.contactEmail || ''}
                  onChange={({ detail: { value } }) => onChange(
                    InfoDataField.CONTACT_EMAIL,
                    value,
                    {
                      isEmail: true,
                      maxLength: 100,
                    },
                  )}
                  data-testid="edit-personal-info-contact-email-input"
                />
              </FormField>
            </div>
          </div>
          <FormField
            label="用户简介"
            errorText={validationError[InfoDataField.SELF_INTRO]}
          >
            <Textarea
              onChange={({ detail: { value } }) => onChange(InfoDataField.SELF_INTRO, value, {
                maxLength: 300,
              })}
              value={userInfoData?.selfIntro}
              data-testid="edit-personal-info-self-intro-input"
            />

          </FormField>

        </SpaceBetween>

      </Container>
    </div>
  );
};

export default EditPersonalInfo;
