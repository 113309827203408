import React from 'react';
import {
  Alert,
} from '@amzn/awsui-components-react';

const DefaultPage = () => (
  <Alert
    data-testid="error-alert"
    statusIconAriaLabel="Error"
    type="error"
  >
    链接错误，请输入正确的地址。
  </Alert>
);

export default DefaultPage;
