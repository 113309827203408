export const ErrorCode = {
  400: 'BAD_REQUEST',
  500: 'INTERNAL_SERVER_ERROR',
  403: 'NO_PERMISSION',
  404: 'NOT_FOUND',
  428: 'TOO_MANY_REQUESTS',
};

export const ErrorCase = {
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  NO_PERMISSION: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 428,
};

export const defaultCompanyFormValidationMap = {
  companyName: '',
  address: '',
  postalCode: '',
  website: '',
};

export const defaultUserInfoFormValidationMap = {
  fullName: '',
  nickName: '',
  selfIntro: '',
  contactPhoneNumber: '',
  contactEmail: '',
  otpCode: '',
};

export enum OtpErrorCode {
  OTP_NOT_EXIST = 'OTP_NOT_EXIST',
  OTP_PHONE_NOT_MATCH = 'OTP_PHONE_NOT_MATCH',
  OTP_CODE_NOT_MATCH = 'OTP_CODE_NOT_MATCH',
  OTP_RETRY_LIMITS = 'OTP_RETRY_LIMITS',
  OTP_SEND_INTERVAL_LIMITED = 'OTP_SEND_INTERVAL_LIMITED',
  INVALID_INPUT = 'INVALID_INPUT',
}
