/* eslint-disable max-len */
import localEnv from '../../public/settings.json';

import axiosInstance from './axios';
import type {
  InfoProps, ResProps, GetOtpProps, VerifyOtpProps, GetOtpResProps, VerifyOtpResProps, EnvProps,
} from './types';
import { getCDNUrl } from './constant';

export const sendOtpApi = (data: GetOtpProps): Promise<GetOtpResProps> => axiosInstance.post('/uc/user_profile/send_otp', {
  ...data,
});

export const verifyOtpApi = (data: VerifyOtpProps): Promise<VerifyOtpResProps> => axiosInstance.post('/uc/user_profile/verify_otp', {
  ...data,
});

export const getInfoApi = (data: any): Promise<InfoProps> => axiosInstance.post('/uc/user_profile/get_user_profile', {
  ...data,
});

export const updateInfoApi = (data: any): Promise<ResProps> => axiosInstance.post('/uc/user_profile/update_user_profile', { ...data });

export const authCallbackApi = (code: string, state: string): Promise<ResProps> => axiosInstance.post(`/passport_callback?code=${code}&state=${state}`);

export const getJobRoleConfigApi = (): Promise<ResProps> => axiosInstance.get(`${getCDNUrl()}/job-role.json`);

export const getCitiesConfigApi = (): Promise<ResProps> => axiosInstance.get(`${getCDNUrl()}/cn-cities.json`);

export const getCompanySizeConfigApi = (): Promise<ResProps> => axiosInstance.get(`${getCDNUrl()}/company-size.json`);

export const getIndustryConfigApi = (): Promise<ResProps> => axiosInstance.get(`${getCDNUrl()}/industry.json`);

export const getIRConfigApi = (): Promise<[]> => axiosInstance.get(`${getCDNUrl()}/information-relationship.json`);

export const getEnv = (): Promise<EnvProps> => {
  const currentDomain = window.location.origin;
  if (currentDomain.includes('localhost')) {
    return Promise.resolve(localEnv);
  }
  return axiosInstance.get(`${currentDomain}/settings.json`);
};
