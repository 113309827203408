import { FEATURE_TOGGLES, getEnvStage } from '../api/constant';

// A simple feature control implementation
export const isFeatureEnabled = (feature: string) => {
  const stage = getEnvStage();
  const featureConfig = FEATURE_TOGGLES[feature];
  if (featureConfig) {
    return !!featureConfig[stage];
  }
  return false;
};
