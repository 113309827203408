import React, {
  useState, useEffect, useRef,
} from 'react';
import './index.scss';
import { Icon, ButtonDropdown } from '@amzn/awsui-components-react';

import AWSHeader from '../../../public/header.png';
import { useAppSelector } from '../../app/hooks';

import {
  ExpandableSection,
  defaultHeaderSectionConfig,
  CREATE_ACCOUNT_ITEMS,
  LOGIN_WEBSITE_ITEMS,
  NAVIGATION_ITEMS_PC,
  CHINA_OFFICIAL_WEBSITE,
  GLOBAL_OFFICIAL_WEBSITE,
} from './constant';

const Header = () => {
  const { userInfo } = useAppSelector((state) => state?.info);
  const [isSectionOpen, setIsSectionOpen] = useState(defaultHeaderSectionConfig);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [profileTextWidth, setProfileTextWidth] = useState(160);
  const [currentDropdownItem, setCurrentDropdownItem] = useState('');
  const [currentDropdownItemOffsetLeft, setCurrentDropdownItemOffsetLeft] = useState(0);
  const navigationRef = useRef(null);
  const arrowWidth = 16;

  const onClickExpandableSection = (id: string) => {
    const newIsSectionOpen = { ...isSectionOpen };
    Object.keys(newIsSectionOpen).forEach((key) => {
      if (key === id) {
        newIsSectionOpen[key] = !newIsSectionOpen[id];
      } else {
        newIsSectionOpen[key] = false;
      }
    });
    setIsSectionOpen(newIsSectionOpen);
    setCurrentDropdownItem(id);
  };

  const calculateItemsWidth = () => {
    let width = 0;
    const navigationItems = navigationRef.current.getElementsByClassName('header-navigation-button');

    for (let i = 0; i < navigationItems.length; i += 1) {
      width += navigationItems[i].offsetWidth;
    }
    return width + (navigationItems.length - 1) * 24;
  };

  const handleScrollLeft = () => {
    navigationRef.current.scrollLeft -= 80;
  };

  const handleScrollRight = () => {
    navigationRef.current.scrollLeft += 80;
  };

  const handleScroll = () => {
    const { scrollLeft } = navigationRef.current;
    setScrollPosition(scrollLeft);
  };

  const handleButtonClick = (href: string) => {
    window.location.href = href;
  };

  useEffect(() => {
    const navigationItems = navigationRef.current.getElementsByClassName('header-navigation-button');
    for (let i = 0; i < navigationItems.length; i += 1) {
      if (navigationItems[i].id === currentDropdownItem) {
        setCurrentDropdownItemOffsetLeft(navigationItems[i].offsetLeft);
      }
    }
  }, [isSectionOpen]);

  useEffect(() => {
    const updateNavigation = () => {
      const currentContainerWidth = navigationRef.current.offsetWidth;
      const itemsWidth = calculateItemsWidth();
      setShowLeftArrow(scrollPosition > 0);
      setShowRightArrow(scrollPosition + currentContainerWidth - arrowWidth < itemsWidth);
    };

    updateNavigation();
    window.addEventListener('resize', updateNavigation);

    return () => {
      window.removeEventListener('resize', updateNavigation);
    };
  }, [scrollPosition]);

  useEffect(() => {
    if (userInfo.nickName) {
      const elements = document.getElementsByClassName('profile-text');
      if (elements.length > 0) {
        const element = elements[0] as HTMLElement;
        const width = element.offsetWidth;
        setProfileTextWidth(width + 48);
      }
    }
  }, [userInfo.nickName]);

  return (
    <div id="header-bar">
      <div className="website-navigation-top">
        <div className="navigation-first-line header-layout">
          <div className="header-logo align-center">
            <img src={AWSHeader} alt="" />
          </div>
          <div className="navigation-first-line-right">
            <a
              href={CHINA_OFFICIAL_WEBSITE}
              target="_blank"
              rel="noopener noreferrer"
              className="nav-text"
            >
              中国官网
            </a>
            <a
              href={GLOBAL_OFFICIAL_WEBSITE}
              target="_blank"
              rel="noopener noreferrer"
              className="nav-text"
            >
              海外官网
            </a>
            <div
              className="awsui-visual-refresh dropdown-parent"
            >
              <ButtonDropdown
                variant="primary"
                items={CREATE_ACCOUNT_ITEMS}
              >
                创建Amazon Web Services账户
              </ButtonDropdown>
            </div>
          </div>
        </div>
        <div className="navigation-second-line align-center header-layout">
          <div className="header-navigation-wrapper">
            <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={() => handleScrollLeft()}>
              {showLeftArrow && <Icon name="angle-left" />}
            </div>
            <div className="header-navigation align-center" ref={navigationRef} onScroll={handleScroll}>
              {NAVIGATION_ITEMS_PC.map((item) => {
                if (item.href) {
                  return (
                    <a
                      className="header-navigation-button"
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.text}
                    </a>
                  );
                }
                if (item.children) {
                  return (
                    <div
                      className="dropdown-parent"
                      onMouseEnter={() => onClickExpandableSection(item.id)}
                      onMouseLeave={() => onClickExpandableSection(item.id)}
                    >
                      <button
                        type="button"
                        id={item.id}
                        className="header-navigation-button invisible-button align-center"
                      >
                        <span>{item.text}</span>
                        {isSectionOpen[item.id]
                          ? <Icon name="caret-up-filled" />
                          : <Icon name="caret-down-filled" />}
                      </button>
                      {isSectionOpen[item.id] && (
                        <div
                          className="dropdown-container"
                          style={scrollPosition > 0 ? { left: currentDropdownItemOffsetLeft - scrollPosition } : {}}
                        >
                          {item.children.map((child) => (
                            <a
                              href={child.href}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-child"
                            >
                              {child.text}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }
                return <> </>;
              })}
            </div>
            <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={() => handleScrollRight()}>
              {showRightArrow && <Icon name="angle-right" />}
            </div>
          </div>

          <div className="header-avatar-button align-center">
            {/* 登出 */}
            <div
              className="dropdown-parent"
              onMouseEnter={() => onClickExpandableSection(ExpandableSection.USER_AVATAR_DROPDOWN)}
              onMouseLeave={() => onClickExpandableSection(ExpandableSection.USER_AVATAR_DROPDOWN)}
            >
              <button
                type="button"
                id="login-website"
                className="header-navigation-button invisible-button align-center"
              >
                <img src={userInfo && userInfo.avatarUrl} alt="" className="profile-icon" />
                <strong className="profile-text">
                  {`欢迎 ${userInfo ? userInfo.nickName : ''}`}
                </strong>
                {isSectionOpen['user-avatar-dropdown']
                  ? <Icon name="caret-up-filled" />
                  : <Icon name="caret-down-filled" />}
              </button>
              {isSectionOpen[ExpandableSection.USER_AVATAR_DROPDOWN] && (
                <div className="dropdown-container" style={{ minWidth: profileTextWidth }}>
                  {LOGIN_WEBSITE_ITEMS.map((item) => (
                    <button
                      type="button"
                      onClick={() => handleButtonClick(item.href)}
                      className="dropdown-child-button invisible-button"
                    >
                      {item.text}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
