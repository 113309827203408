import React, { useRef, useState, useEffect } from 'react';
import type { AppLayoutProps } from '@amzn/awsui-components-react';
import {
  AppLayout, StatusIndicator,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

import Navigation from './components/navigation';
import ContentRoutes from './components/content';
import { authCallbackApi } from './api/api';
import { setupResponseInterceptor } from './api/axios';
import Header from './components/header';
import MobileHeader from './components/mobile-header';
import Footer from './components/footer';
import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import {
  getInfoAsync, getEnvAsync, setIsCheckingAuthStatus, setIsWechatEnv,
} from './app/infoReducer';
import { mobileInnerWidth } from './constants/config';

const App = () => {
  const dispatch = useAppDispatch();
  const { isCheckingAuth, isWechatEnv } = useAppSelector((state) => state?.info);

  const appLayout = useRef<AppLayoutProps.Ref>(null);
  const navigate = useNavigate();
  const currentLocation = window.location.href;
  const isAuthCallback = currentLocation.indexOf('code') !== -1;
  const isErrorPage = currentLocation.indexOf('error') !== -1;

  const [isLoaded, setIsLoaded] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(!isErrorPage && window.innerWidth > mobileInnerWidth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  if (!isLoaded) {
    setIsLoaded(true);
    setupResponseInterceptor(navigate);
  }

  const onNavigationToggle = (status: boolean) => {
    setNavigationOpen(status);
  };

  const getHeaderComponent = () => {
    let headerComponent;
    if (isWechatEnv) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      headerComponent = <></>;
    } else {
      headerComponent = windowWidth > mobileInnerWidth ? <Header /> : <MobileHeader />;
    }
    return headerComponent;
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < mobileInnerWidth) {
        setNavigationOpen(false);
      } else {
        setNavigationOpen(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!isAuthCallback && !isCheckingAuth) {
      const drawerContent = document.querySelectorAll('[class*=awsui_drawer-content]');
      if (drawerContent.length > 0) {
        drawerContent.forEach((dc) => {
          const d = dc as HTMLElement;
          d.style.top = '108px';
        });
      }

      const contentWrapper = document.querySelectorAll('[class*=awsui_content-wrapper]');
      if (contentWrapper.length > 0) {
        contentWrapper.forEach((cw) => {
          const c = cw as HTMLElement;
          c.style.paddingBottom = '0px';
        });
      }
    }
  }, [isCheckingAuth, isAuthCallback]);

  useEffect(() => {
    if (!isAuthCallback && !isErrorPage) {
      dispatch(getInfoAsync({
        infoType: 'all',
      }))
        .then((response: any) => {
          const { error } = response;
          if (error && error.message) {
            const errorObject = JSON.parse(error.message);
            const { statusCode } = errorObject;
            if (!(Number(statusCode) === 403)) {
              dispatch(setIsCheckingAuthStatus(false));
            }
          } else {
            dispatch(setIsCheckingAuthStatus(false));
          }
        });
    }
    if (isErrorPage) {
      dispatch(setIsCheckingAuthStatus(false));
    }
  }, [dispatch, isAuthCallback, isErrorPage]);

  useEffect(() => {
    (async () => {
      if (isAuthCallback) {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        if (code && state) {
          await authCallbackApi(code, state);
        }
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(getEnvAsync());
  }, []);

  useEffect(() => {
    if (navigator.userAgent) {
      dispatch(setIsWechatEnv(navigator.userAgent.indexOf('miniProgram') > -1));
    }
  }, [dispatch]);

  return (
    (isAuthCallback || isCheckingAuth) ? <StatusIndicator type="loading" />
      : (
        <>
          {getHeaderComponent()}
          <div className={isWechatEnv ? 'website-wrapper-miniapp' : 'website-wrapper'}>
            <AppLayout
              ref={appLayout}
              content={<ContentRoutes />}
              navigationHide={isWechatEnv}
              navigation={<Navigation onNavigationToggle={onNavigationToggle} />}
              navigationOpen={navigationOpen}
              onNavigationChange={(event: {
                detail: { open: React.SetStateAction<boolean> };
              }) => setNavigationOpen(event.detail.open)}
              toolsHide
              headerSelector="#header-bar"
              footerSelector="#footer-bar"
            />
          </div>
          {!isWechatEnv && <Footer />}
        </>
      )
  );
};

export default App;
