import { getLogoutAccountUrl } from '../../api/constant';

export const defaultHeaderSectionConfig = {
  'user-avatar-dropdown': false,
  'trending-tech': false,
  'industry-topic': false,
  'high-level': false,
  cooperator: false,
  conference: false,
  'login-website': false,
  'create-account': false,
  developer: false,
  profile: false,
};

export enum ExpandableSection {
  USER_AVATAR_DROPDOWN = 'user-avatar-dropdown',
  TRENDING_TECH = 'trending-tech',
  INDUSTRY_TOPIC = 'industry-topic',
  HIGH_LEVEL = 'high-level',
  COOPERATOR = 'cooperator',
  CONFERENCE = 'conference',
  LOGIN_WEBSITE = 'login-website',
  CREATE_ACCOUNT = 'create-account',
  DEVELOPER = 'developer',
}

const TRENDING_TECH_ITEMS = [
  {
    id: 'trending-tech-1',
    text: 'AIGC创新实践在线大会',
    href: 'https://webinar.amazoncloud.cn/AIGC0309/listDetails.html',
  },
  {
    id: 'trending-tech-2',
    text: '出海创新大会',
    href: 'https://webinar.amazoncloud.cn/goglobal2022/listDetails.html',
  },
  {
    id: 'trending-tech-3',
    text: '云原生数据库创新日',
    href: 'https://webinar.amazoncloud.cn/DataVirtualEvent/listDetails.html',
  },
  {
    id: 'trending-tech-4',
    text: '云原生数据库十周年庆',
    href: 'https://webinar.amazoncloud.cn/CloudNative/listDetails.html',
  },
  {
    id: 'trending-tech-5',
    text: '元宇宙创新实践在线大会',
    href: 'https://webinar.amazoncloud.cn/Metaverse/listDetails.html',
  },
  {
    id: 'trending-tech-6',
    text: '智能物联网创新日',
    href: 'https://webinar.amazoncloud.cn/AIoT/listDetails.html',
  },
];

const INDUSTRY_TOPIC_ITEMS = [
  {
    id: 'industry-topic-1',
    text: '游戏开发者大会',
    href: 'https://webinar.amazoncloud.cn/GameTechDay2023/listDetails.html',
  },
  {
    id: 'industry-topic-2',
    text: '碳路未来 云赋新生',
    href: 'https://webinar.amazoncloud.cn/specialTopic/Carbon.html?vep_st=33538',
  },
  {
    id: 'industry-topic-3',
    text: '中国企业下午茶',
    href: 'https://webinar.amazoncloud.cn/specialTopic/Tea-Break.html?vep_st=33539',
  },
];

const HIGH_LEVEL_ITEMS = [
  {
    id: 'high-executive-1',
    text: '线上思享会',
    href: 'https://webinar.amazoncloud.cn/specialTopic/yuntus.html?vep_st=33533',
  },
];

const COOPERATOR_ITEMS = [
  {
    id: 'cooperator-1',
    text: '合作伙伴创新加速周',
    href: 'https://webinar.amazoncloud.cn/specialTopic/Partner_week.html?vep_st=33532',
  },
  {
    id: 'cooperator-2',
    text: '中国企业出海季',
    href: 'https://webinar.amazoncloud.cn/specialTopic/themepage.html?vep_st=33537',
  },
];

const CONFERENCE_ITEMS = [
  {
    id: 'flagship-1',
    text: '2023 SUMMIT 中国峰会',
    href: 'https://summit.awsevents.cn/2023/form.html?source=3fPhinn2zTN4FtuBIFqNaOjUJVZj4EVU20oi8/3naNY=',
  },
  {
    id: 'flagship-2',
    text: '2023 创新大会-全面拥抱Serverless时代',
    href: 'https://webinar.amazoncloud.cn/innovate/serverless/?s=8278&smid=17157',
  },
  {
    id: 'flagship-3',
    text: '2022 re:Invent 全球大会',
    href: 'https://webinar.amazoncloud.cn/reInvent2022/?s=7982&smid=14810',
  },
];

const DEVELOPER_ITEMS = [
  {
    id: 'developer-1',
    text: '专栏',
    href: 'https://dev.amazoncloud.cn/column/columnIndex',
  },
  {
    id: 'developer-2',
    text: '问答',
    href: 'https://dev.amazoncloud.cn/interlocution',
  },
  {
    id: 'developer-3',
    text: '视频',
    href: 'https://dev.amazoncloud.cn/video',
  },
  {
    id: 'developer-4',
    text: '社区',
    href: 'https://dev.amazoncloud.cn/community',
  },
  {
    id: 'developer-5',
    text: '活动',
    href: 'https://dev.amazoncloud.cn/activity',
  },
  {
    id: 'developer-6',
    text: '大赛',
    href: 'https://dev.amazoncloud.cn/competition',
  },
];

export const LOGIN_WEBSITE_ITEMS = [
  {
    id: 'user-center',
    text: '用户中心',
    href: '#',
  },
  {
    id: 'logout-website',
    text: '退出登录',
    href: getLogoutAccountUrl(),
  },
];

export const CREATE_ACCOUNT_ITEMS = [
  {
    id: 'create-account-cn',
    text: '中国区域账户注册',
    href: 'https://signin.amazonaws.cn/signup?request_type=register',
  },
  {
    id: 'create-account-global',
    text: '海外区域账户注册',
    href: 'https://portal.aws.amazon.com/billing/signup#/start/email',
  },
];

export const NAVIGATION_ITEMS_PC = [
  {
    id: 'all-activities',
    text: '所有活动',
    href: 'https://webinar.amazoncloud.cn/index.aspx',
  },
  {
    id: 'developer',
    text: '开发者',
    children: DEVELOPER_ITEMS,
  },
  {
    id: 'trending-tech',
    text: '技术热点',
    children: TRENDING_TECH_ITEMS,
  },
  {
    id: 'industry-topic',
    text: '行业专题',
    children: INDUSTRY_TOPIC_ITEMS,
  },
  {
    id: 'high-level',
    text: '高管专区',
    children: HIGH_LEVEL_ITEMS,
  },
  {
    id: 'cooperator',
    text: '合作伙伴',
    children: COOPERATOR_ITEMS,
  },
  {
    id: 'training',
    text: '培训和认证',
    href: 'https://webinar.amazoncloud.cn/specialTopic/training.html?vep_st=33540',
  },
  {
    id: 'innovation',
    text: '创新成长企业',
    href: 'https://webinar.amazoncloud.cn/specialTopic/smb-collection.html?vep_st=33545',
  },
  {
    id: 'conference',
    text: '旗舰大会',
    children: CONFERENCE_ITEMS,
  },
];

export const CHINA_OFFICIAL_WEBSITE = 'https://www.amazonaws.cn/?trk=vep-nav';
export const GLOBAL_OFFICIAL_WEBSITE = 'https://aws.amazon.com/';

export const NAVIGATION_ITEMS_MOBILE = [
  {
    id: 'chinese-portal-website',
    text: '中国官网',
    href: CHINA_OFFICIAL_WEBSITE,
  },
  {
    id: 'global-portal-website',
    text: '海外官网',
    href: GLOBAL_OFFICIAL_WEBSITE,
  },
  {
    id: 'profile',
    text: '欢迎',
    children: LOGIN_WEBSITE_ITEMS,
  },
  ...NAVIGATION_ITEMS_PC,
];
