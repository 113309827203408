/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  defaultUserInfoState,
  defaultAccountInfoState,
  defaultCompanyInfoState,
  defaultStage,
  defaultUserAgreementState,
} from '../constants/config';
import {
  getInfoApi,
  updateInfoApi,
  getJobRoleConfigApi,
  getCitiesConfigApi,
  getCompanySizeConfigApi,
  getIndustryConfigApi,
  getIRConfigApi,
  getEnv,
} from '../api/api';
import { avatarUrls } from '../api/constant';
import type {
  CompanyInfoProps, IndividualInfoProps, ReducerState, UserAgreementProps,
} from '../api/types';

export const getInfoAsync = createAsyncThunk(
  'userCenterInfo/fetchInfo',
  async (params: { infoType: 'userInfo' | 'companyInfo' | 'userAgreement' | 'all' }) => {
    const response = await getInfoApi({});
    return { data: response, infoType: params.infoType };
  },
);

export const getEnvAsync = createAsyncThunk(
  'userCenterInfo/fetchEnv',
  async () => {
    const response = await getEnv();
    return response;
  },
);

export const updateUserInfoAsync = createAsyncThunk(
  'userCenterInfo/updateInfo',
  async (data: IndividualInfoProps, { dispatch }) => {
    const response = await updateInfoApi({ ...data });
    await dispatch(getInfoAsync({
      infoType: 'userInfo',
    }));
    return response;
  },
);

export const updateCompanyInfoAsync = createAsyncThunk(
  'userCenterInfo/updateInfo',
  async (data: CompanyInfoProps, { dispatch }) => {
    const copied = { ...data };
    const { informationRelationship } = data;
    delete copied?.informationRelationship;
    const requestBody = {
      company: { ...copied },
      informationRelationship,
    };
    const response = await updateInfoApi(requestBody);
    await dispatch(getInfoAsync({
      infoType: 'companyInfo',
    }));
    return response;
  },
);

export const updateUserAgreementAsync = createAsyncThunk(
  'userCenterInfo/updateUserAgreement',
  async (data: UserAgreementProps, { dispatch }) => {
    const response = await updateInfoApi({
      marketingTouchPolicyConsented: data.marketingTouchPolicy,
    });
    await dispatch(getInfoAsync({
      infoType: 'userAgreement',
    }));
    return response;
  },
);

export const getConfigAsync = createAsyncThunk(
  'userCenterInfo/getConfig',
  async () => {
    const promises = [
      getJobRoleConfigApi(),
      getCitiesConfigApi(),
      getCompanySizeConfigApi(),
      getIndustryConfigApi(),
      getIRConfigApi(),
    ];
    return Promise.all(promises).then((responses) => responses);
  },
);

export const infoSlice = createSlice({
  name: 'userCenterInfo',
  initialState: {
    isWechatEnv: false,
    stage: defaultStage,
    userInfo: defaultUserInfoState,
    companyInfo: defaultCompanyInfoState,
    accountInfo: defaultAccountInfoState,
    isGetInfoLoading: true,
    isGetCompanyInfoLoading: true,
    // Beta Env / localhost set to false, otherwise set to true
    // eslint-disable-next-line max-len
    isCheckingAuth: !((window.location.href.indexOf('beta') !== -1 || window.location.href.indexOf('localhost') !== -1)),
    isEditingPersonStatus: false,
    isEditingCompanyStatus: false,
    onNavigationHref: '',
    isShowConfirmationModal: false,
    configResponse: [],
    isGetUserAgreementLoading: true,
    userAgreements: defaultUserAgreementState,
    isEditingUserAgreement: false,
  } as ReducerState,
  reducers: {
    setLoading: (state, action) => {
      state.isGetInfoLoading = action.payload;
    },
    setIsEditPersonalStatus: (state, action) => {
      state.isEditingPersonStatus = action.payload;
    },
    setIsEditCompanyStatus: (state, action) => {
      state.isEditingCompanyStatus = action.payload;
    },
    setIsConfirmationModalStatus: (state, action) => {
      state.isShowConfirmationModal = action.payload;
    },
    setOnNavigationHref: (state, action) => {
      state.onNavigationHref = action.payload;
    },
    setIsCheckingAuthStatus: (state, action) => {
      state.isCheckingAuth = action.payload;
    },
    setIsWechatEnv: (state, action) => {
      state.isWechatEnv = action.payload;
    },
    setIsEditUserAgreementStatus: (state, action) => {
      state.isEditingUserAgreement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfigAsync.fulfilled, (state, { payload }) => {
        state.configResponse = payload;
      })
      .addCase(getEnvAsync.fulfilled, (state, { payload }) => {
        if (payload.stage) {
          state.stage = payload.stage;
        }
      })
      .addCase(getInfoAsync.pending, (state, { meta }) => {
        const { arg: { infoType } } = meta;
        if (infoType === 'userInfo' || infoType === 'all') {
          state.isGetInfoLoading = true;
        }
        if (infoType === 'companyInfo' || infoType === 'all') {
          state.isGetCompanyInfoLoading = true;
        }
        if (infoType === 'userAgreement' || infoType === 'all') {
          state.isGetUserAgreementLoading = true;
        }
      })
      .addCase(getInfoAsync.fulfilled, (state, { payload }) => {
        const { data, infoType } = payload;

        if (infoType === 'userInfo' || infoType === 'all') {
          state.userInfo = {
            fullName: data.fullName,
            nickName: data.nickName,
            selfIntro: data.selfIntro,
            avatarUrl: (data.avatarUrl && data.avatarUrl !== '') ? data.avatarUrl : avatarUrls[0],
            contactPhoneNumber: data.contactPhoneNumber,
            contactEmail: data.contactEmail,
          };
          state.accountInfo = {
            email: data.email,
            mobilePhoneNumber: data.mobilePhoneNumber,
            existDuplicateUser: data.existDuplicateUser,
            wechatId: data.wechatId,
          };
          state.isGetInfoLoading = false;
        }

        if (infoType === 'companyInfo' || infoType === 'all') {
          state.companyInfo = {
            address: data.company?.address,
            city: data.company?.city,
            companyName: data.company?.companyName,
            companySize: data.company?.companySize,
            industry: data.company?.industry,
            jobRole: data.company?.jobRole,
            postalCode: data.company?.postalCode,
            province: data.company?.province,
            useCase: data.company?.useCase,
            website: data.company?.website,
          };
          state.isGetCompanyInfoLoading = false;
        }

        if (infoType === 'userAgreement' || infoType === 'all') {
          state.userAgreements = {
            marketingTouchPolicy: data.marketingTouchPolicyConsented,
          };
          state.isGetUserAgreementLoading = false;
        }
      })
      .addCase(getInfoAsync.rejected, (state, { meta }) => {
        const { arg: { infoType } } = meta;
        if (infoType === 'userInfo' || infoType === 'all') {
          state.isGetInfoLoading = false;
        }
        if (infoType === 'companyInfo' || infoType === 'all') {
          state.isGetCompanyInfoLoading = false;
        }
        if (infoType === 'userAgreement' || infoType === 'all') {
          state.isGetUserAgreementLoading = false;
        }
      });
  },
});

export const {
  setIsEditPersonalStatus,
  setIsEditCompanyStatus,
  setIsCheckingAuthStatus,
  setIsConfirmationModalStatus,
  setOnNavigationHref,
  setIsWechatEnv,
  setIsEditUserAgreementStatus,
} = infoSlice.actions;

export default infoSlice.reducer;
