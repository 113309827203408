export const columnDefinitions = [
  {
    id: 'title',
    header: '信息项',
    cell: (item: any) => item.title || '-',
    width: '350px',
  },
  {
    id: 'value',
    header: '内容',
    cell: (item: any) => item.value || '-',
  },
];

export const defaultUserInfoState = {
  fullName: '',
  nickName: '',
  selfIntro: '',
  avatarUrl: '',
  contactPhoneNumber: '',
  contactEmail: '',
};

export const defaultAccountInfoState = {
  email: '',
  mobilePhoneNumber: '',
  existDuplicateUser: false,
  wechatId: '',
};
export const defaultCompanyInfoState = {
  address: '',
  city: '',
  companyName: '',
  companySize: '',
  industry: '',
  jobRole: '',
  postalCode: '',
  province: '',
  useCase: '',
  website: '',
  informationRelationship: '',
};

export const defaultUserAgreementState = {
  marketingTouchPolicy: false,
};

export const locale = 'zh-CN';
export const defaultStage: string = 'beta';

export const mobileInnerWidth = 768;
