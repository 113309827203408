/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  BreadcrumbGroup, Header, SpaceBetween, Modal, Button, Box, StatusIndicator,
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

import './index.scss';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setIsConfirmationModalStatus, setIsEditPersonalStatus, setIsEditCompanyStatus } from '../../app/infoReducer';
import BackgroundImg from '../../../public/background.png';
import { metric } from '../../utils/monitor';

import PersonalInfo from './personalInfo';
import PersonalInfoNew from './personalInfoNew';
import CompanyInfo from './companyInfo';
import UserAgreement from './userAgreement';

const BasicInfo = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    stage,
    isShowConfirmationModal,
    onNavigationHref,
    isGetInfoLoading,
    isGetCompanyInfoLoading,
    isWechatEnv,
    isGetUserAgreementLoading,
  } = useAppSelector((state) => state?.info);

  const handleOnCancelLeaveModal = () => {
    dispatch(setIsConfirmationModalStatus(false));
  };

  const handleOnConfirmLeaveModal = () => {
    dispatch(setIsConfirmationModalStatus(false));
    dispatch(setIsEditPersonalStatus(false));
    dispatch(setIsEditCompanyStatus(false));
    navigate(onNavigationHref);
  };

  const onClickGoBack = () => {
    wx.miniProgram.reLaunch({
      url: '/pages/my/my',
    });
  };

  useEffect(() => {
    metric([], 'BasicInfo Page PV', 1, [], 'Count');
  }, []);

  return (
    <div className="basic-info">
      {!isWechatEnv
        ? (
          <div className="basic-info-breadcrumb">
            <BreadcrumbGroup
              items={[
                { text: '用户中心', href: '/' },
                { text: '我的信息', href: '/my-info' },
                {
                  text: '基本信息',
                  href: '/my-info/basic',
                },
              ]}
              ariaLabel="Breadcrumbs"
            />
          </div>
        ) : (
          <Button iconName="angle-left" variant="icon" onClick={() => onClickGoBack()} />
        )}
      <div className="basic-info-header">
        <Header
          variant="h1"
        >
          基本信息
        </Header>
      </div>
      <Modal
        onDismiss={() => handleOnCancelLeaveModal()}
        visible={isShowConfirmationModal}
        closeAriaLabel="Close modal"
        footer={(
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => handleOnCancelLeaveModal()}
                data-testid="confirmation-modal-cancel-button"
              >
                取消

              </Button>
              <Button
                variant="primary"
                onClick={() => handleOnConfirmLeaveModal()}
                data-testid="confirmation-modal-confirm-button"
              >
                确定
              </Button>
            </SpaceBetween>
          </Box>
            )}
        header="离开当前页面"
        data-testid="confirmation-modal"
      >
        您确定要离开当前页面吗？您编辑的内容还未保存，如果您直接离开当前页面，您编辑的内容将无法保存。
      </Modal>
      <div>
        <div className="basic-info-table">
          <SpaceBetween size="l">
            {isGetInfoLoading ? (
              <div className="basic-info-loading">
                <StatusIndicator type="loading" />
              </div>
            ) : <PersonalInfoNew />}
            {isGetCompanyInfoLoading ? (
              <div className="basic-info-loading">
                <StatusIndicator type="loading" />
              </div>
            ) : <CompanyInfo />}
            {isGetUserAgreementLoading ? (
              <div className="basic-info-loading">
                <StatusIndicator type="loading" />
              </div>
            ) : <UserAgreement />}
          </SpaceBetween>
        </div>
        <img src={BackgroundImg} alt="" className="background-img" />
      </div>
    </div>
  );
};

export default BasicInfo;
