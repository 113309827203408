import React, { useState } from 'react';
import {
  Header, Button, Container,
} from '@amzn/awsui-components-react';

import EditPersonalInfo from '../../editBasicInfo/editPersonalNew';
import { avatarUrls } from '../../../api/constant';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setIsEditPersonalStatus } from '../../../app/infoReducer';
import { log, metric } from '../../../utils/monitor';
import './index.scss';

const PersonalInfo = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state?.info);
  const [isEditPersonalInfo, setIsEditPersonalInfo] = useState<boolean>(false);

  const onClickEditPersonalInfoButton = () => {
    setIsEditPersonalInfo(!isEditPersonalInfo);
    dispatch(setIsEditPersonalStatus(true));
    log('INFO', `editPersonalInfo ${JSON.stringify(userInfo.nickName)}`);
    metric([], 'Click Edit PersonalInfo Button', 1, [], 'Count');
  };

  const onUpdateEditStatusFn = (status: boolean) => {
    setIsEditPersonalInfo(status);
  };

  const getAvatarUrl = () => {
    if (userInfo?.avatarUrl && userInfo?.avatarUrl !== '') {
      return userInfo?.avatarUrl;
    }
    return avatarUrls[0];
  };

  return (
    isEditPersonalInfo
      ? (
        <EditPersonalInfo
          updateEditStatusFn={onUpdateEditStatusFn}
          userInfo={userInfo}
          defaultAvatar={getAvatarUrl()}
        />
      )
      : (
        <Container
          header={(
            <Header actions={(
              <Button
                data-testid="edit-personal-info-button"
                onClick={() => onClickEditPersonalInfoButton()}
              >
                编辑
              </Button>
            )}
            >
              个人信息
            </Header>
)}
        >
          <div className="personal-info-container">
            <div className="img-line-container">
              <img src={getAvatarUrl()} alt="" className="personal-avatar" />
            </div>
            <div className="info-line-container">
              <div className="info-line">
                <div className="info-line-title">昵称</div>
                <div className="info-line-content">{userInfo?.nickName || '-'}</div>
              </div>
              <div className="info-line">
                <div className="info-line-title">姓名</div>
                <div className="info-line-content">{userInfo?.fullName || '-'}</div>
              </div>
              <div className="info-line">
                <div className="info-line-title">联系手机号码</div>
                <div className="info-line-content">{userInfo?.contactPhoneNumber || '-'}</div>
              </div>
              <div className="info-line">
                <div className="info-line-title">联系邮箱地址</div>
                <div className="info-line-content">{userInfo?.contactEmail || '-'}</div>
              </div>
              <div className="info-line">
                <div className="info-line-title">用户简介</div>
                <div className="info-line-content">{userInfo?.selfIntro || '-'}</div>
              </div>
            </div>
          </div>
        </Container>
      )
  );
};

export default PersonalInfo;
