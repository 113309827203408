const STAGES = {
  BETA: 'beta',
  PREPROD: 'preprod',
  PROD: 'prod',
};

const TEST_ENV = STAGES.PREPROD;

// const REGIONS: { [key: string]: string; } = {
//   beta: 'us-west-2',
//   preprod: 'cn-northwest-1',
//   prod: 'cn-northwest-1',
// };

export const getEnvStage = () => {
  const location = window.location.href;
  if (location.indexOf('localhost') !== -1 || location.indexOf('127.0.0.1') !== -1) {
    return TEST_ENV;
  }
  if (location.indexOf('beta') !== -1) {
    return STAGES.BETA;
  }
  if (location.indexOf('gamma') !== -1) {
    return STAGES.PREPROD;
  }
  return STAGES.PROD;
};

const BACKEND_URLS: { [key: string]: string } = {
  beta: 'https://api.account-beta.amazoncloud.cn',
  preprod: 'https://api.account-gamma.amazoncloud.cn',
  prod: 'https://api.account.amazoncloud.cn',
};

export const getBackendURL = () => BACKEND_URLS[getEnvStage()];

const CDN_URLS: { [key: string]: string } = {
  beta: 'https://account-beta.amazoncloud.cn/config',
  preprod: 'https://account-gamma.amazoncloud.cn/config',
  prod: 'https://account.amazoncloud.cn/config',
};

export const getCDNUrl = () => CDN_URLS[getEnvStage()];

const CHANGE_PASSWORD_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/changepassword/',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/changepassword/',
  prod: 'https://passport.amazoncloud.cn/account/changepassword/',
};

export const getChangePasswordUrl = () => CHANGE_PASSWORD_URLS[getEnvStage()];

const CHANGE_EMAIL_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/changeemail/',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/changeemail/',
  prod: 'https://passport.amazoncloud.cn/account/changeemail/',
};

export const getChangeEmailUrl = () => CHANGE_EMAIL_URLS[getEnvStage()];

const CHANGE_PHONE_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/changephone/',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/changephone/',
  prod: 'https://passport.amazoncloud.cn/account/changephone/',
};

export const getChangePhoneUrl = () => CHANGE_PHONE_URLS[getEnvStage()];

const DELETE_ACCOUNT_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/deleteaccount/',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/deleteaccount/',
  prod: 'https://passport.amazoncloud.cn/account/deleteaccount/',
};

export const getDeleteAccountUrl = () => DELETE_ACCOUNT_URLS[getEnvStage()];

const LOGOUT_ACCOUNT_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/signout',
  preprod: 'https://passport-gamma.amazoncloud.cn/signout',
  prod: 'https://passport.amazoncloud.cn/signout',
};

export const getLogoutAccountUrl = () => LOGOUT_ACCOUNT_URLS[getEnvStage()];

const CREATE_ACCOUNT_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/signup',
  preprod: 'https://passport-gamma.amazoncloud.cn/signup',
  prod: 'https://passport.amazoncloud.cn/signup',
};

export const getCreateAccountUrl = () => CREATE_ACCOUNT_URLS[getEnvStage()];

const BIND_USER_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/bindwechat/',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/bindwechat/',
  prod: 'https://passport.amazoncloud.cn/account/bindwechat/',
};

export const getBindUserUrl = () => BIND_USER_URLS[getEnvStage()];

const UNBIND_USER_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/unbindwechat/',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/unbindwechat/',
  prod: 'https://passport.amazoncloud.cn/account/unbindwechat/',
};

export const getUnbindUserUrl = () => UNBIND_USER_URLS[getEnvStage()];

const FAQ_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/cn/faq',
  preprod: 'https://passport-gamma.amazoncloud.cn/cn/faq',
  prod: 'https://passport.amazoncloud.cn/cn/faq',
};

export const getFaqUrl = () => FAQ_URLS[getEnvStage()];

export const GET_ENDPOINT_URL = (path: string) => {
  const currentLocation = window.location.href;
  if (currentLocation.indexOf('code') !== -1) {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    return `/${path}?code=${code}&state=${state}`;
  }
  return `/uc/user_profile/${path}`;
};

export const avatarUrls = new Array(10).fill(0).map((_, index) => `${getCDNUrl()}/images/user-photo-${index + 1}.png`);

export const MONITOR_BACKEND_URLS: { [key: string]: string } = {
  beta: 'https://api-beta.cloudassist.sign-up.china.aws.a2z.com',
  preprod: 'https://api-gamma.cloudassist.cn-northwest-1.amazonaws.com.cn',
  prod: 'https://api.cloudassist.cn-northwest-1.amazonaws.com.cn',
};

const getMonitorBackendUrl = () => `${MONITOR_BACKEND_URLS[getEnvStage()]}/logmonitor/log/publish`;
export const REPORT_URL = getMonitorBackendUrl();

export const SKIP_ERROR_PAGES_ENDPOINT_URLS = ['/uc/user_profile/send_otp', '/uc/user_profile/verify_otp'];

const MANAGE_MFA_URLS: { [key: string]: string } = {
  beta: 'https://passport-beta.amazoncloud.cn/account/managemfa',
  preprod: 'https://passport-gamma.amazoncloud.cn/account/managemfa',
  prod: 'https://passport.amazoncloud.cn/account/managemfa',
};

export const getManageMfaUrl = () => MANAGE_MFA_URLS[getEnvStage()];

export const FEATURE_TOGGLES = {
  MANAGE_MFA_FEATURE_TOGGLE: {
    beta: true,
    preprod: true,
    prod: true,
  },
  // Add more features as needed
};
