import React, { useState, useEffect } from 'react';
import { Table, Header, Button } from '@amzn/awsui-components-react';

import EditCompanyInfo from '../../editBasicInfo/editCompany';
import { columnDefinitions, locale } from '../../../constants/config';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setIsEditCompanyStatus } from '../../../app/infoReducer';
import { log, metric } from '../../../utils/monitor';

import { idTitleMap, InfoDataField } from './type';

export const findLabelByValue = (info: any, attribute: string, data: any) => {
  const value = info[`${attribute}`];

  if (value && typeof data === 'object' && value in data) {
    const label = data[value][`${locale}`];
    return label;
  }
  return '';
};

export const findCityLabelByValue = (info: any, attribute: string, data: any) => {
  const selectedProvince = info[InfoDataField.PROVINCE];
  if (typeof data === 'object' && selectedProvince in data) {
    const { codes } = data[selectedProvince];
    return findLabelByValue(info, attribute, codes);
  }
  return '';
};

const CompanyInfo = () => {
  const dispatch = useAppDispatch();
  const { companyInfo, configResponse } = useAppSelector((state) => state?.info);
  const [isEditCompanyInfo, setIsEditCompanyInfo] = useState<boolean>(false);
  const [companyInfoData, setCompanyInfoData] = useState(companyInfo);
  const [companyInfoItems, setCompanyInfoItems] = useState<any>();
  const [isDataStructFinished, setIsDataStructFinished] = useState(false);

  const onClickEditCompanyInfoButton = () => {
    setIsEditCompanyInfo(!isEditCompanyInfo);
    dispatch(setIsEditCompanyStatus(true));
    log('INFO', `editCompanyInfo ${JSON.stringify(companyInfo.companyName)}`);
    metric([], 'Click Edit CompanyInfo Button', 1, [], 'Count');
  };

  const onUpdateEditStatusFn = (status: boolean) => {
    setIsEditCompanyInfo(status);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted
          && configResponse.length > 0) {
      try {
        const jobRoleData = configResponse[0];
        const jobRoleLabel = findLabelByValue(companyInfo, InfoDataField.JOB_ROLE, jobRoleData);
        const provinceData = configResponse[1];
        const provinceLabel = findLabelByValue(companyInfo, InfoDataField.PROVINCE, provinceData);
        const cityLabel = findCityLabelByValue(companyInfo, InfoDataField.CITY, provinceData);
        const companySizeData = configResponse[2];
        const companySizeLabel = findLabelByValue(companyInfo, InfoDataField.COMPANY_SIZE, companySizeData);
        const industryData = configResponse[3];
        const industryLabel = findLabelByValue(companyInfo, InfoDataField.INDUSTRY, industryData);
        const iRData = configResponse[4];
        const informationRelationLabel = findLabelByValue(companyInfo, InfoDataField.INFORMATION_RELATIONSHIP, iRData);
        setCompanyInfoData({
          ...companyInfoData,
          [InfoDataField.CITY]: cityLabel,
          [InfoDataField.COMPANY_SIZE]: companySizeLabel,
          [InfoDataField.INDUSTRY]: industryLabel,
          [InfoDataField.JOB_ROLE]: jobRoleLabel,
          [InfoDataField.PROVINCE]: provinceLabel,
          [InfoDataField.INFORMATION_RELATIONSHIP]: informationRelationLabel,
        });
        setIsDataStructFinished(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      setIsDataStructFinished(true);
    }

    return () => {
      isMounted = false;
    };
  }, [configResponse]);

  useEffect(() => {
    if (isDataStructFinished) {
      let items: any = [];
      items = Object.keys(idTitleMap).map((key) => ({
        id: key,
        title: idTitleMap[`${key}`] || '-',
        value: companyInfoData[`${key}`] || '-',
      }));
      setCompanyInfoItems(items);
    }
  }, [companyInfoData, isDataStructFinished]);

  return (
    isEditCompanyInfo
      ? <EditCompanyInfo updateEditStatusFn={onUpdateEditStatusFn} companyInfo={companyInfo} />
      : (
        <Table
          columnDefinitions={columnDefinitions}
          items={companyInfoItems}
          // loading={!companyInfoItems}
          loadingText="Loading Profile Information"
          sortingDisabled
          header={(
            <Header actions={(
              <Button
                data-testid="edit-company-info-button"
                onClick={() => onClickEditCompanyInfoButton()}
              >
                编辑
              </Button>
          )}
            >
              公司信息
            </Header>
          )}
        />
      )
  );
};

export default CompanyInfo;
